<template>
  <div class="page-content">
    <van-cell-group title="档案信息">
      <van-cell :value="listData.realname" title="客户姓名" />
      <van-cell title="来电/店">{{listData.income_type == '1' ? '来店' : '来电'}}</van-cell>
      <van-cell :value="listData.income_date" title="进店日期" />
      <van-cell :value="listData.income_time_section" title="来访时间段" />
      <van-cell title="进离店时间">{{listData.income_time_begin}} - {{listData.income_time_end}}</van-cell>
      <van-cell title="留店时长">{{listData.income_time_duration}}分钟</van-cell>
      <van-cell title="来店人数">{{listData.income_number}}人</van-cell>
      <van-cell :value="getCfgText(listData.client_type)" title="客户类型" />
      <van-cell :value="listData.wechat" title="微信号" />
      <van-cell :value="listData.sex == '1' ? '男' : '女'" title="性别" />
      <van-cell :value="listData.is_first_income == '1' ? '首次' : '再次'" title="客户属性" />
      <van-cell :value="getCfgText(listData.region)" title="区域" />
      <van-cell :value="listData.has_seat == '1' ? '是' : '否'" title="是否入座" />
      <van-cell :value="listData.has_offer == '1' ? '是' : '否'" title="是否报价" />
      <van-cell :value="listData.is_vip == '1' ? '是' : '否'" title="是否重点客户" />
      <van-cell :value="listData.has_trial == '1' ? '是' : '否'" title="是否试驾" />
      <van-cell :value="listData.trial_memo" title="试驾备注" />
      <van-cell :value="listData.has_pick == '1' ? '是' : '否'" title="现提车辆" />
      <van-cell :value="getCfgText(listData.income_purpose)" title="来店目的" />
      <van-cell :value="getCfgText(listData.target_model)" title="需求车型" />
      <van-cell :value="getCfgText(listData.transmission)" title="变速箱类型" />
      <van-cell :value="getCfgText(listData.displacement)" title="需求排量" />
      <van-cell :value="getCfgText(listData.outer_color)" title="外观颜色" />
      <van-cell :value="getCfgText(listData.inner_color)" title="内饰颜色" />
      <van-cell :value="getCfgText(listData.clue_source)" title="线索渠道" />
      <van-cell :value="getCfgText(listData.marketing_analysis)" title="营销分析" />
      <van-cell :value="getCfgText(listData.client_cate)" title="客户类别" />
      <van-cell :value="getCfgText(listData.affected_item)" title="影响因素" />
      <van-cell :value="listData.has_effective == '1' ? '是' : '否'" title="客户有效性" />
      <van-cell :value="getCfgText(listData.buy_type)" title="购车类型" />
      <van-cell :value="listData.used_model" title="二手车型" />
      <van-cell :value="listData.used_arrived == '1' ? '是' : '否'" title="二手车到店" />
      <van-cell :value="listData.used_surveyed == '1' ? '是' : '否'" title="是否勘察" />
      <van-cell :value="listData.valuator" title="评估师" />
      <van-cell :value="listData.region" title="简述" />
    </van-cell-group>
  </div>
</template>
<script>
export default {
  props: ['client_id'],
  data() {
    return {
      listData: {}
    }
  },
  mounted() {
    this.view()
  },
  methods: {
    getCfgText(key) {
      if (key == '') {
        return '-'
      }

      if (this.$store.state.cfgData[key] != undefined) {
        return this.$store.state.cfgData[key][0]
      } else {
        return '-'
      }
    },
    view() {
      this.$axios
        .post('/client/detail', {
          client_id: this.client_id
        })
        .then(res => {
          this.listData = res.data.data
        })
    }
  }
}
</script>
<style lang="less" scoped>
.page-content {
  height: calc(100vh - 46px);
  overflow-y: auto;
}
</style>