<template>
  <div class="main" data-title="客户跟踪" v-title>
    <div class="btn-sticky">
      <span @click="showClientDetail">
        <van-icon name="notes-o" size="18" style="margin-right: 20px" />
      </span>
      <span @click="loadLastTrack">
        <van-icon @click="loadData = true" name="replay" size="18" v-show="!loadData" />
        <van-loading color="#1989fa" size="18" v-show="loadData" />
      </span>
    </div>

    <van-form @submit="onSubmit">
      <van-cell-group title="跟踪信息">
        <van-field :label="'客户姓名'" disabled placeholder="请输入" required v-model="form.realname" />

        <field-date
          :max-date="new Date()"
          :rules="[{required: true, message: '不能为空'}]"
          label="跟踪时间"
          required
          type="date"
          v-model="form.trace_date"
        />

        <field-date label="预计购车日期" type="date" v-model="form.expect_buy_date" />
        <van-field
          :rules="[{required: true, message: '不能为空'}]"
          label="车型媒体价"
          placeholder="请输入"
          required
          type="number"
          v-model="form.media_amount"
        />
        <van-field label="优惠折扣" placeholder="请输入" type="number" v-model="form.discount_amount" />

        <field-select
          :columns="cfgListArr('customerLevel')"
          :label="'客户级别'"
          :rules="[{ required: true, message: '不能为空' }]"
          @change="changeCustomerLevel"
          isLink
          placeholder="请选择"
          required
          v-model="form.client_level"
        ></field-select>

        <van-field label="竞争品牌" placeholder="请输入" v-model="form.contend_brand" />
        <van-field label="竞争车型" placeholder="请输入" v-model="form.contend_model" />
        <van-field
          :disabled="true"
          label="车辆净价"
          placeholder="计算中"
          type="number"
          v-model="form.net_amount"
        />
        <van-field label="二手车折价" placeholder="请输入" type="number" v-model="form.used_car_amount" />
        <field-date
          :rules="[{ required: true, message: '不能为空' }]"
          label="预计下次回访日期"
          label-width="120px"
          required
          type="date"
          v-model="form.next_trace_date"
        />
        <van-field
          :rules="[{ required: true, message: '不能为空' }]"
          label="预估保费"
          placeholder="请输入"
          required
          type="number"
          v-model="form.insurance_tax_amount"
        />
        <van-field
          label="车辆购置税"
          placeholder="请输入"
          type="number"
          v-model="form.purchase_tax_amount"
        />
        <!-- <field-date label="邀约到店日期" label-width="120px" type="date" v-model="form.expect_pay_date" /> -->
        <range-calendar
          @change="setIncomeDate"
          isLink
          label="邀约到店日期"
          placeholder="请选择"
          v-model="form.invite_income_date_begin_end"
        />

        <field-select
          :columns="cfgListArr('loansType')"
          :label="'贷款方式'"
          isLink
          placeholder="请选择"
          v-model="form.credit_type"
        ></field-select>
        <van-field
          :rules="[{ required: true, message: '不能为空' }]"
          label="综合服务费"
          placeholder="请输入"
          required
          type="number"
          v-model="form.service_tax_amount"
        />
        <van-field label="购买精品金额" placeholder="请输入" type="number" v-model="form.buy_parts_amount" />
        <field-select
          :columns="cfgListArr('trackingType')"
          :label="'跟踪类型'"
          :rules="[{ required: true, message: '不能为空' }]"
          isLink
          placeholder="请选择"
          required
          v-model="form.trace_type"
        ></field-select>

        <field-select
          :columns="cfgListArr('Result')"
          :label="'促进结果'"
          :rules="[{ required: true, message: '不能为空' }]"
          isLink
          placeholder="请选择"
          required
          v-model="form.trace_status"
        ></field-select>
        <van-field
          autosize
          label="购买精品明细"
          placeholder="请输入"
          rows="2"
          type="textarea"
          v-model="form.buy_parts_items"
        />
        <van-field
          autosize
          label="赠送精品明细"
          placeholder="请输入"
          rows="2"
          type="textarea"
          v-model="form.donate_parts_items"
        />
        <van-field
          :disabled="true"
          label="预计总费用"
          placeholder="计算中"
          type="number"
          v-model="form.tax_amount"
        />
        <van-field label="跟踪文件" name="uploader">
          <template #input>
            <van-uploader
              :after-read="afterRead"
              :before-delete="beforedelAudio"
              @click-preview="filePreview"
              accept="image/*, audio/*"
              v-model="uploader"
            />
          </template>
        </van-field>
        <van-field
          autosize
          label="跟踪描述"
          placeholder="请输入"
          rows="2"
          type="textarea"
          v-model="form.trace_memo"
        />
      </van-cell-group>
      <van-cell-group title="贷款信息" v-if="form.credit_type != ''">
        <van-field
          label="贷款金额"
          placeholder="请输入"
          type="number"
          v-model="form.tmp_credit_detail.loan"
        />
        <van-field
          label="首付金额"
          placeholder="请输入"
          type="number"
          v-model="form.tmp_credit_detail.downPayment"
        />
        <van-field
          label="月供金额"
          placeholder="请输入"
          type="number"
          v-model="form.tmp_credit_detail.monthlyPayment"
        />
        <van-field
          label="利息金额"
          placeholder="请输入"
          type="number"
          v-model="form.tmp_credit_detail.interest"
        />
        <!-- <van-field
          label="银行审核费"
          placeholder="请输入"
          type="number"
          v-model="form.tmp_credit_detail.cost1"
        />
        <van-field
          label="贷款审核费"
          placeholder="请输入"
          type="number"
          v-model="form.tmp_credit_detail.cost2"
        />
        <van-field
          label="车辆抵押费"
          placeholder="请输入"
          type="number"
          v-model="form.tmp_credit_detail.cost3"
        /> -->
      </van-cell-group>

      <van-cell-group title="保险信息" v-if="form.credit_type != ''">
        <van-field label="三责" name="radio">
          <template #input>
            <van-radio-group direction="horizontal" v-model="form.tmp_insurance_detail.three">
              <van-radio :name="1">需要</van-radio>
              <van-radio :name="2">不需要</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          label="金额"
          placeholder="请输入"
          type="number"
          v-model="form.tmp_insurance_detail.threePayment"
        />
        <van-field label="交强险" name="radio">
          <template #input>
            <van-radio-group direction="horizontal" v-model="form.tmp_insurance_detail.insurance1">
              <van-radio :name="1">需要</van-radio>
              <van-radio :name="2">不需要</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field label="车损险" name="radio">
          <template #input>
            <van-radio-group direction="horizontal" v-model="form.tmp_insurance_detail.insurance2">
              <van-radio :name="1">需要</van-radio>
              <van-radio :name="2">不需要</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <!-- <van-field label="不计免赔" name="radio">
          <template #input>
            <van-radio-group direction="horizontal" v-model="form.tmp_insurance_detail.insurance3">
              <van-radio :name="1">需要</van-radio>
              <van-radio :name="2">不需要</van-radio>
            </van-radio-group>
          </template>
        </van-field> -->
        <van-field label="划痕险" name="radio">
          <template #input>
            <van-radio-group direction="horizontal" v-model="form.tmp_insurance_detail.insurance4">
              <van-radio :name="1">需要</van-radio>
              <van-radio :name="2">不需要</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field label="盗抢险" name="radio">
          <template #input>
            <van-radio-group direction="horizontal" v-model="form.tmp_insurance_detail.insurance5">
              <van-radio :name="1">需要</van-radio>
              <van-radio :name="2">不需要</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <!-- <van-field label="玻璃险" name="radio">
          <template #input>
            <van-radio-group direction="horizontal" v-model="form.tmp_insurance_detail.insurance6">
              <van-radio :name="1">需要</van-radio>
              <van-radio :name="2">不需要</van-radio>
            </van-radio-group>
          </template>
        </van-field> -->
        <van-field label="意外伤害险" name="radio">
          <template #input>
            <van-radio-group direction="horizontal" v-model="form.tmp_insurance_detail.insurance7">
              <van-radio :name="1">需要</van-radio>
              <van-radio :name="2">不需要</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field label="座位险" name="radio">
          <template #input>
            <van-radio-group direction="horizontal" v-model="form.tmp_insurance_detail.insurance8">
              <van-radio :name="1">需要</van-radio>
              <van-radio :name="2">不需要</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field label="随车行李险" name="radio">
          <template #input>
            <van-radio-group direction="horizontal" v-model="form.tmp_insurance_detail.insurance9">
              <van-radio :name="1">需要</van-radio>
              <van-radio :name="2">不需要</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <!-- <van-field label="自燃险" name="radio">
          <template #input>
            <van-radio-group direction="horizontal" v-model="form.tmp_insurance_detail.insurance10">
              <van-radio :name="1">需要</van-radio>
              <van-radio :name="2">不需要</van-radio>
            </van-radio-group>
          </template>
        </van-field> -->
      </van-cell-group>
      <van-cell-group title="其它信息">
        <field-date label="订车日期" type="date" v-model="form.book_date" />
        <field-date label="交车日期" type="date" v-model="form.deliver_date" />
        <field-date label="退订日期" type="date" v-model="form.unbook_date" />
        <field-date label="失控日期" type="date" v-model="form.lose_date" />
        <field-date label="战败日期" type="date" v-model="form.fall_date" />

        <van-field label="战败品牌" placeholder="请输入" v-model="form.fall_brand" />
        <field-select
          :columns="cfgListArr('fallReason')"
          :label="'战败原因'"
          isLink
          placeholder="请选择"
          v-model="form.fall_reason"
        ></field-select>
        <van-field label="战败车型" placeholder="请输入" v-model="form.fall_model" />

        <van-field
          autosize
          label="战败说明"
          placeholder="请输入"
          rows="2"
          type="textarea"
          v-model="form.fall_memo"
        />
      </van-cell-group>

      <van-cell-group title="其它信息" v-if="configList.length">
        <van-field
          :key="item.sys_id"
          :label="item.name"
          placeholder="请输入"
          v-for="item in configList"
          v-model="form.tmp_custom_data['field_'+item.sys_id]"
        />
      </van-cell-group>
      <div style="margin: 16px;">
        <van-button block native-type="submit" round type="info">提交</van-button>
      </div>
    </van-form>
    <van-popup
      @closed="pause"
      @opened="play"
      position="bottom"
      style="padding: 20px 0; text-align:center"
      v-model="showAudio"
    >
      <audio :src="audioSrc" controls="controls" id="audio" ref="audio"></audio>
    </van-popup>
  </div>
</template>
<script>
import { dateFormat, sellList, cfgList } from '@/unit/unit.js'
import FieldSelect from '@/components/FieldSelect.vue'
import FieldDate from '@/components/FieldDate.vue'
import CustomerDetail from '@/components/CustomerDetail.vue'
import RangeCalendar from '@/components/RangeCalendar.vue'
import wx from 'weixin-js-sdk'
// import PlayerAudio from 'vue-player-audio'
export default {
  components: {
    FieldSelect,
    FieldDate,
    CustomerDetail,
    RangeCalendar
    // PlayerAudio
  },
  data() {
    return {
      showClient: false,
      show: false,

      uploader: [],
      loadData: false,
      showAudio: false,
      form: {
        client_id: this.$route.query.client_id,
        realname: '',
        expect_buy_date: '',
        media_amount: '',
        purchase_tax_amount: '',
        trace_date: dateFormat(),
        contend_brand: '',
        discount_amount: '',
        buy_parts_amount: '',
        client_level: '',
        contend_model: '',
        net_amount: '',
        buy_parts_items: '',
        next_trace_date: '',
        old_seller_uid: '',
        used_car_amount: '',
        donate_parts_items: '',
        // expect_pay_date: '',
        invite_income_date_begin_end: '',
        invite_income_date_begin: '',
        invite_income_date_end: '',
        call_audio_file: '',
        call_audio_list: [],
        insurance_tax_amount: '',
        trace_type: '',
        service_tax_amount: '',
        trace_status: '',
        tax_amount: '',
        credit_type: '',
        tmp_credit_detail: {
          loan: '',
          downPayment: '',
          monthlyPayment: '',
          interest: '',
          cost1: '',
          cost2: '',
          cost3: ''
        },
        credit_detail: '',
        tmp_insurance_detail: {
          three: 2,
          threePayment: '',
          insurance1: 2,
          insurance2: 2,
          insurance3: 2,
          insurance4: 2,
          insurance5: 2,
          insurance6: 2,
          insurance7: 2,
          insurance8: 2,
          insurance9: 2,
          insurance10: 2
        },
        insurance_detail: '',
        trace_memo: '',
        book_date: null,
        deliver_date: null,
        unbook_date: null,
        lose_date: '',

        fall_brand: '',
        fall_model: '',
        fall_reason: '',
        fall_memo: '',
        custom_data: '',
        tmp_custom_data: {},

        checkedIns: []
      },
      configList: [],

      oss_token: '',
      audioSrc: ''
    }
  },
  mounted() {
    this.init()
    this.$axios.post('/audio/oss_token').then(res => {
      if (res.data.code == 200) {
        this.oss_token = res.data.data
      }
    })
  },
  methods: {
    showClientDetail() {
      this.$router.push({name: 'customer-datadetail', query: {client_id: this.$route.query.client_id}})
    },
    setIncomeDate() {
      if (this.form.invite_income_date_begin_end) {
        this.form.invite_income_date_begin = this.form.invite_income_date_begin_end.split(
          '/'
        )[0]
        this.form.invite_income_date_end = this.form.invite_income_date_begin_end.split(
          '/'
        )[1]
      } else {
        this.form.invite_income_date_begin = ''
        this.form.invite_income_date_end = ''
      }
    },
    //文件上传
    afterRead(file) {
      let _this = this
      file.status = 'uploading'
      file.message = '上传中...'

      var formData = new FormData()

      formData.append(
        'key',
        _this.oss_token.dir + Date.parse(new Date()) + '/' + file.file.name
      )
      formData.append('policy', _this.oss_token.policy)
      formData.append('OSSAccessKeyId', _this.oss_token.access_key)
      formData.append('success_action_status', 200)
      formData.append('signature', _this.oss_token.signature)
      formData.append('callback', _this.oss_token.callback)
      formData.append('name', file.file.name)
      formData.append('file', file.file, file.file.name)

      this.$axios.post(_this.oss_token.host, formData).then(res => {
        // this.$refs.upload.clearFiles()
        if (res.data.code == 200) {
          file.status = 'done'
          file.message = '上传成功'
          this.form.call_audio_list.push({
            name: res.data.data.file_name,
            url: res.data.data.file_url,
            file_id: res.data.data.file_id
          })
        }
      })
    },
    beforedelAudio(file) {
      let index = this.uploader.indexOf(file)
      this.form.call_audio_list.splice(index, 1)
      return true
    },

    filePreview(file) {
      if (file.file.type.split('/')[0] == 'audio') {
        let index = this.uploader.indexOf(file)
        this.audioSrc = this.form.call_audio_list[index].url
        this.showAudio = true
      }
    },

    play() {
      this.$refs.audio.play()
    },
    pause() {
      this.$refs.audio.pause()
    },

    changeCustomerLevel() {
      if (this.form.trace_date == '' || this.form.client_level == '') {
        return false
      }

      let days = this.$store.state.cfgData[this.form.client_level][1]
      this.form.next_trace_date = this.addDate(this.form.trace_date, days)

      if (this.form.client_level == 22) {
        this.rules.fall_reason = [
          { required: true, message: '必需填写', trigger: 'change' }
        ]
      }
    },
    addDate(startDate, days) {
      startDate = new Date(startDate)
      startDate = +startDate + 1000 * 60 * 60 * 24 * days
      startDate = new Date(startDate)
      var mm =
        startDate.getMonth() + 1 < 10
          ? '0' + (startDate.getMonth() + 1)
          : startDate.getMonth() + 1
      var dd =
        startDate.getDate() < 10
          ? '0' + startDate.getDate()
          : startDate.getDate()

      var nextStartDate = startDate.getFullYear() + '-' + mm + '-' + dd
      return nextStartDate
    },

    init() {
      //拉取客户信息
      this.getTrackList()

      //拉取所有自定义字段
      this.$axios
        .post('/cfgfield/lists', {
          key: 'kehugenzong'
        })
        .then(res => {
          this.configList = res.data.data[0].list
        })
    },
    loadLastTrack() {
      this.$axios
        .post('/client/last_trace', {
          client_id: parseInt(this.$route.query.client_id) || 0
        })
        .then(res => {
          if (res.data.code == 200) {
            if (JSON.stringify(res.data.data) == '{}') {
              this.$notify({ type: 'danger', message: '没有上次跟踪记录' })
              this.loadData = false
            } else {
              delete res.data.data.trace_date
              this.setData(res)
              setTimeout(() => {
                this.$notify({
                  type: 'success',
                  message: '成功载入上次跟踪记录'
                })
                this.loadData = false
              }, 1000)
            }
          }
        })
    },
    //载入上次跟踪记录或根据trace_id修改本次跟踪记录
    setData(res) {
      this.form.trace_id = res.data.data.trace_id
      this.form.expect_buy_date = res.data.data.expect_buy_date
      this.form.media_amount = res.data.data.media_amount
      this.form.purchase_tax_amount = res.data.data.purchase_tax_amount
      if (res.data.data.trace_date) {
        this.form.trace_date = res.data.data.trace_date
      }

      this.form.contend_brand = res.data.data.contend_brand
      this.form.discount_amount = res.data.data.discount_amount
      this.form.buy_parts_amount = res.data.data.buy_parts_amount
      this.form.client_level =
        res.data.data.client_level == ''
          ? ''
          : parseInt(res.data.data.client_level)
      this.form.contend_model = res.data.data.contend_model
      this.form.net_amount = res.data.data.net_amount
      this.form.buy_parts_items = res.data.data.buy_parts_items
      this.form.next_trace_date = res.data.data.next_trace_date
      this.form.old_seller_uid = res.data.data.old_seller_uid
      this.form.used_car_amount = res.data.data.used_car_amount
      this.form.donate_parts_items = res.data.data.donate_parts_items
      // this.form.expect_pay_date = res.data.data.expect_pay_date
      this.form.invite_income_date_begin =
        res.data.data.invite_income_date_begin
      this.form.invite_income_date_end = res.data.data.invite_income_date_end
      this.form.invite_income_date_begin_end =
        res.data.data.invite_income_date_begin +
        '/' +
        res.data.data.invite_income_date_end
      this.form.insurance_tax_amount = res.data.data.insurance_tax_amount
      this.form.trace_type =
        res.data.data.trace_type == '' ? '' : parseInt(res.data.data.trace_type)
      this.form.service_tax_amount = res.data.data.service_tax_amount
      this.form.trace_status =
        res.data.data.trace_status == ''
          ? ''
          : parseInt(res.data.data.trace_status)
      this.form.tax_amount = res.data.data.tax_amount
      this.form.credit_type =
        res.data.data.credit_type == ''
          ? ''
          : parseInt(res.data.data.credit_type)
      this.form.trace_memo = res.data.data.trace_memo
      this.form.tmp_credit_detail = JSON.parse(res.data.data.credit_detail)
      this.form.tmp_insurance_detail = JSON.parse(
        res.data.data.insurance_detail
      )

      this.form.book_date = res.data.data.book_date
      this.form.deliver_date = res.data.data.deliver_date
      this.form.unbook_date = res.data.data.unbook_date
      this.form.lose_date = res.data.data.lose_date
      this.form.fall_date = res.data.data.fall_date
      this.form.fall_brand = res.data.data.fall_brand
      this.form.fall_model = res.data.data.fall_model
      this.form.fall_reason =
        res.data.data.fall_reason == ''
          ? ''
          : parseInt(res.data.data.fall_reason)
      this.form.fall_memo = res.data.data.fall_memo
      if (res.data.data.audio_file_list) {
        this.form.call_audio_list = res.data.data.audio_file_list
      }

      this.form.call_audio_file = res.data.data.call_audio_file
      this.form.tmp_custom_data = eval('(' + res.data.data.custom_data + ')')
    },
    onSubmit() {
      if (this.form.call_audio_list.length > 0) {
        let arr = []
        this.form.call_audio_list.map(item => {
          arr.push(item.file_id)
        })
        this.form.call_audio_file = arr.join('|')
      } else {
        this.form.call_audio_file = ''
      }

      this.form.custom_data = JSON.stringify(this.form.tmp_custom_data)
      this.form.insurance_detail = JSON.stringify(
        this.form.tmp_insurance_detail
      )
      this.form.credit_detail = JSON.stringify(this.form.tmp_credit_detail)
      // if (this.form.expect_pay_date == '') {
      //   delete this.form.expect_pay_date
      // }

      if (this.form.invite_income_date_begin == '') {
        delete this.form.invite_income_date_begin
      }

      if (this.form.invite_income_date_end == '') {
        delete this.form.invite_income_date_end
      }

      if (this.form.expect_buy_date == '') {
        delete this.form.expect_buy_date
      }

      if (this.form.media_amount == '') {
        delete this.form.media_amount
      }

      if (this.form.purchase_tax_amount == '') {
        delete this.form.purchase_tax_amount
      }

      if (this.form.discount_amount == '') {
        delete this.form.discount_amount
      }

      if (this.form.buy_parts_amount == '') {
        delete this.form.buy_parts_amount
      }

      if (this.form.net_amount == '') {
        delete this.form.net_amount
      }

      if (this.form.used_car_amount == '') {
        delete this.form.used_car_amount
      }

      if (this.form.insurance_tax_amount == '') {
        delete this.form.insurance_tax_amount
      }

      if (this.form.service_tax_amount == '') {
        delete this.form.service_tax_amount
      }

      if (this.form.tax_amount == '') {
        delete this.form.tax_amount
      }

      if (this.form.book_date == '') {
        delete this.form.book_date
      }

      if (this.form.deliver_date == '') {
        delete this.form.deliver_date
      }

      if (this.form.unbook_date == '') {
        delete this.form.unbook_date
      }
      if (this.form.lose_date == '') {
        delete this.form.lose_date
      }

      this.$axios.post('/trace/create', this.form).then(res => {
        if (res.data.code == 200) {
          // this.$notify({ type: 'success', message: res.data.msg })
          // this.$toast.success(res.data.msg)

          this.$toast(res.data.msg)

          //跳转至登记列表
          setTimeout(() => {
            wx.miniProgram.navigateBack({
              url: '/pages/trace/main'
            })
          }, 2000)
        } else {
          this.$notify({ type: 'danger', message: res.data.msg })
        }
      })
    },
    sellArr() {
      return sellList()
    },
    cfgListArr(type) {
      return cfgList(type)
    },
    //获取所有跟踪客户列表
    getTrackList() {
      this.$axios.post('/index/client').then(res => {
        this.form.realname = res.data.data.list[this.$route.query.client_id]
      })
    }
  },
  computed: {
    listenChange() {
      const { form } = this
      return { form }
    }
  },
  watch: {
    listenChange: {
      handler: function(val) {
        let media_amount = isNaN(parseFloat(val.form.media_amount))
          ? 0
          : parseFloat(val.form.media_amount)
        let discount_amount = isNaN(parseFloat(val.form.discount_amount))
          ? 0
          : parseFloat(val.form.discount_amount)

        this.form.net_amount =
          media_amount - discount_amount == 0
            ? ''
            : media_amount - discount_amount

        let purchase_tax_amount = isNaN(
          parseFloat(val.form.purchase_tax_amount)
        )
          ? 0
          : parseFloat(val.form.purchase_tax_amount)
        let buy_parts_amount = isNaN(parseFloat(val.form.buy_parts_amount))
          ? 0
          : parseFloat(val.form.buy_parts_amount)
        let used_car_amount = isNaN(parseFloat(val.form.used_car_amount))
          ? 0
          : parseFloat(val.form.used_car_amount)
        let insurance_tax_amount = isNaN(
          parseFloat(val.form.insurance_tax_amount)
        )
          ? 0
          : parseFloat(val.form.insurance_tax_amount)
        let service_tax_amount = isNaN(parseFloat(val.form.service_tax_amount))
          ? 0
          : parseFloat(val.form.service_tax_amount)

        let tax_amount =
          this.form.net_amount +
          purchase_tax_amount +
          buy_parts_amount -
          used_car_amount +
          insurance_tax_amount +
          service_tax_amount
        this.form.tax_amount = tax_amount == 0 ? '' : tax_amount
      },
      deep: true
    }
  }
}
</script>
<style lang="less" scoped>
.main {
  /deep/ .van-sticky:before {
    display: none;
  }

  /deep/ .van-popup:before {
    height: 2.5rem;
    background: none;
  }
}

.btn-sticky {
  display: flex;
  position: fixed;
  padding: 10px 20px;
  right: 0px;
  color: #1989fa;
  z-index: 999;
}
</style>